import './styles.scss';
import React, {memo} from 'react';

import {
    AvatarImage,
    BigLogoRight,
    masterCardV2,
    VisaV2,
    AppStoreV2,
    GPlayV2,
    designedBy,
    feelGplay,
    feelApay
} from "../../images";
import { Link } from 'gatsby';

type Props = {
    pagePath: string,
    btnType: string,
    linkTo: string,
}

const MainFooter2 = ({pagePath, btnType, linkTo}: Props) => {

    return (
        <footer className="container footer" id={'contacts'}>
            <div className="footerSecondLeft">
                <div className="footerSecondLeftAvatar">
                    <img src={AvatarImage} width={96} height={96} alt=""/>
                </div>
                <div className="footerSecondLeftData">
                    <div className="footerSecondLeftPosition">СЕО</div>
                    <div className="footerSecondLeftName">Andrew Filatov</div>
                    <div className="footerSecondLeftDesc">
                        My goal is to create a product that helps people improve themselves
                    </div>
                </div>
            </div>

            <div className="footerSecondCenter">
                <div className="footerSecondPayments">
                    <div>
                        <img src={masterCardV2} width={24} alt=""/>
                    </div>
                    <div>
                        <img src={VisaV2} width={24} alt=""/>
                    </div>
                </div>
                <div className="footerSecondStores">
                    <div>
                        {
                            (btnType === 'appStore') ? (
                                <a 
                                    href={linkTo ? linkTo : 'https://apps.apple.com/us/app/aeer-education-productivity/id1630377140?l=ru'}
                                    id={`appStore${pagePath}Link`}
                                    className="noEventsInside">
                                    <img width={132} src={feelApay} alt=""/>
                                </a>
                            ) : (
                                <a 
                                    onClick={() => showForm(true)}
                                    id={`appStore${pagePath}Link`}
                                    className="noEventsInside">
                                    <img width={132} src={feelApay} alt=""/>
                                </a>
                            )
                        }
                        
                    </div>
                    <div>
                        {
                            (btnType === 'appStore') ? (
                                <a href={linkTo ? linkTo : 'https://apps.apple.com/us/app/aeer-education-productivity/id1630377140?l=ru'}
                                    id={`GPlay${pagePath}Link`}
                                    className="noEventsInside">
                                    <img width={132} src={feelGplay} alt=""/>
                                </a>
                            ) : (
                                <a 
                                    onClick={() => showForm(true)}
                                    id={`GPlay${pagePath}Link`}
                                    className="noEventsInside">
                                    <img width={132} src={feelGplay} alt=""/>
                                </a>
                            )
                        }
                    </div>
                </div>
                <div className="footerSecondLinks">
                    <Link to="/terms" id={`terms${pagePath}link`}>Terms of User</Link>
                    <Link to="/privacy" id={`privacy${pagePath}link`}>Privacy policy</Link>
                    <Link to="/cookie" id={`cookie${pagePath}link`}>Cookie policy</Link>
                    <Link to="/cancel" id={`cancel${pagePath}link`}>Unsubscribe</Link>
                </div>
            </div>

            <div className="footerSecondRight">
                <img width={70} className="footerSecondLogo" src={designedBy} alt=""/>
                <div className="footerSecondCopy">
                    © AEER PLATFORM INC 
                </div>
                <address className="footerSecondAddress">
                    8 THE GREEN STE A DOVER, DE 19901
                </address>
            </div>
        </footer>
    )
}

export default memo(MainFooter2);
